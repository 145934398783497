import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { captureException } from "@sentry/react";

let source = axios.CancelToken.source();

export default function Invalid() {
    const [html, setHtml] = useState("");
    const navigate = useNavigate();

    async function fetchHtml() {
        axios({
            method: "GET",
            url: `${window.backend_url}/forms/GetHtml`,
            cancelToken: source.token,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": `${window.frontend_url}`,
            },
        })
            .then((response) => {
                setHtml(response.data.html);

                setTimeout(() => {
                    window.open(response.data.redirect, "_self");
                }, 10000);
            })
            .catch((err) => {
                captureException(err);
                navigate("/error");
            });
    }

    useEffect(() => {
        fetchHtml();

        return () => {
            source.cancel();
        };
    }, []);

    function createMarkup() {
        return { __html: html };
    }

    if (!html)
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100vw",
                    height: "100vh",
                }}
            >
                <Spinner color="red" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );

    return <div dangerouslySetInnerHTML={createMarkup()} />;
}
