import React, { useEffect, useState } from 'react'
import { Lookup } from 'devextreme-react/lookup';
import { useContext } from 'react';
import { FormContext } from '../Context/FormContext'

export default React.memo(function LookupField({ field_id, field_label, field_value, field_mandatory, field_items, field_dspExpr, field_validation, field_response_errors }) {
    const { handleChange } = useContext(FormContext)
    const [lookupItems, setLookupItems] = useState()

    function valueChanged(e) {
        if (e.value)
            handleChange(field_id, { target: { value: [e.value.value] } })
        else
            handleChange(field_id, { target: { value: [""] } })
    }

    useEffect(() => {
        setLookupItems(field_items)
    }, [field_items])

    return (
        <div className="row">
            <label>{field_label} {field_mandatory === "yes" ? '*' : ''}</label>
            {lookupItems ? <Lookup
                className="field-customization"
                items={lookupItems}
                displayExpr={field_dspExpr}
                defaultValue={field_items?.filter(item => item.value === field_value[0])[0]}
                showClearButton={true}
                onValueChanged={e => valueChanged(e)}
            /> : <p>error getting field</p>}
            {
                field_validation.map((item) => {
                    if (item["show_error"] === "true")
                        return <p id={item.validation_id} key={item.validation_id} className="text-error error-element">{item["error_message"]}</p>
                    else
                        return null
                })
            }
            {
                field_response_errors ?
                    field_response_errors.map((item) => {
                        return <p key={item.id} className="text-error">{item.error_message}</p>
                    })
                    :
                    null
            }
        </div>

    )
})
