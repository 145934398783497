import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import Element from "./Element";
import { FormContext } from "./Context/FormContext";
import axios from "axios";
import Progress from "./UI/Progress";
import { useLocation, useNavigate } from "react-router-dom";
import Preloader from "./UI/Preloader";
import FormHeader from "./UI/FormHeader";
import Container from "./UI/Container";
import FormStyling from "./CustomStyles/FormStyling";
import GeneralStyling from "./CustomStyles/GeneralStyling";
import FormFooter from "./UI/FormFooter";
import * as Helper from "./Helpers/FormComponentHelper";
import * as Validate from "./Validation/Validate";
import { captureException } from "@sentry/react";
import { set } from "lodash";
import CustomCss from "./CustomStyles/CustomCss";

export default function FormComponent() {
  let navigate = useNavigate();

  const [formElements, setFormElements] = useState(null);
  const [formTitle, setFormTitle] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [formType, setFormType] = useState(null);
  const [token, setToken] = useState(null);
  const [logo, setLogo] = useState(null);
  const [thankyouPage, setThankyouPage] = useState(null);
  const [styles, setStyles] = useState(null);
  const [progressBarMessage, setProgressBarMessage] = useState(null);
  const [preloaderMessage, setPreloaderMessage] = useState(null);
  const [formHeaderParagraphs, setFormHeaderParagraphs] = useState(null);
  const [formFooterTitle, setFormFooterTitle] = useState(null);
  const [formFooterParagraphs, setFormFooterParagraphs] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [generalErrors, setGeneralErrors] = useState(null);
  const [typeOfHeader, setTypeOfHeader] = useState(null);
  const location = useLocation();

  async function handleLoaded(e) {
    e.preventDefault();
    await window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute("6LfrcO8nAAAAAIvKxQFxrqu34eIRFv3A83xupxoa", {
          action: "homepage",
        })
        .then((data) => {
          setToken(data);
        });
    });
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=6LfrcO8nAAAAAIvKxQFxrqu34eIRFv3A83xupxoa";
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (token) {
      handleSubmit();
    }
  }, [token]);

  function lookupErrorsEelements() {
    if (document.querySelector(".error-element")) {
      const element =
        document.querySelector(".error-element").getBoundingClientRect().top + window.pageYOffset - 150;
      window.scrollTo({ top: element, behavior: "smooth" });
      return true;
    }

    return false;
  }

  async function handleSubmit() {
    setGeneralErrors(null);
    const [isRequiredValid, newElements] = Validate.checkRequired(formElements);
    const isFilesValid = Validate.checkFilesErrors();

    setFormElements(newElements);

    if (!isRequiredValid || !isFilesValid) {
      let foundErrorMessage;
      for (let index = 0; index < 3; index++) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        foundErrorMessage = lookupErrorsEelements();
        if (foundErrorMessage) break;
      }

      if (!foundErrorMessage) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }

    if (isRequiredValid && isFilesValid) {
      const formRequestData = Helper.createDataForPostRequest(formElements);

      const data = {
        form_id: formType,
        google_recaptcha_token: token,
        fields: JSON.stringify(formRequestData),
        hash: `${location.pathname.split("/")[2]}`,
      };

      setIsUploading(true);
      try {
        const response = await axios({
          method: "POST",
          url: `${window.backend_url}/forms/SaveForm/${location.pathname.split("/")[1]}`,
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `${window.frontend_url}`,
          },
          data: JSON.stringify(data),
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadPercentage(percentage);
            if (percentage === 100) {
              setTimeout(() => {
                setIsDownloading(true);
                setIsUploading(false);
              }, 1000);
            }
          },
        });
        if (response.data === "success")
          navigate("/thankyou", {
            state: { thankyouPage, logo, formTitle, formHeaderParagraphs, typeOfHeader },
          });
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            if (error.response.data["fields"]) {
              error.response.data["fields"].forEach((item) => {
                handleResponseErrors(item.field_id, item.errors);
              });
            }
            if (error.response.data["errors"]) setGeneralErrors(error.response.data["errors"]);
          }

          return;
        }

        captureException(error);
        navigate("/error");

        setTimeout(() => {
          setUploadPercentage(0);
          setIsUploading(false);
          setIsDownloading(false);
        }, 1500);
      }
    }
  }

  const handleResponseErrors = (id, errors) => {
    const newElements = [...formElements];

    newElements.forEach((field) => {
      const { field_id } = field;
      if (id === field_id) {
        field.field_response_errors = errors;
      }
    });
    setFormElements(newElements);
  };

  const handleChange = (id, event) => {
    const newElements = [...formElements];

    newElements.forEach((field) => {
      const { field_type, field_id } = field;
      if (id === field_id) {
        if (field_type === "checkbox") {
          field = Helper.checkboxChange(field, event);
        } else if (field_type === "multipleFile" || field_type === "file") {
          field = Helper.fileChange(field, event);
        } else {
          field = Helper.generalChange(field, event);
        }
      }
    });
    setFormElements(newElements);
  };

  async function GetForm() {
    try {
      const response = await axios(`${window.backend_url}/forms/form/${location.pathname.split("/")[1]}`);

      const formShape = await response.data;

      setFormElements(formShape.fields);
      setFormTitle(formShape["form_title"]);
      setFormType(formShape["form_id"]);
      setLogo(formShape["logo_url"]);
      if (formShape["header_type"]) {
        setTypeOfHeader(formShape["header_type"]);
      } else {
        setTypeOfHeader("row");
      }
      setThankyouPage({
        title: formShape["thankyou_title"],
        message: formShape["thankyou_message"],
      });
      setStyles(formShape["styles"]);
      setProgressBarMessage(formShape["progressbar_message"]);
      setPreloaderMessage(formShape["preloader_message"]);
      setFormHeaderParagraphs(formShape["form_header_paragraphs"]);
      setFormFooterTitle(formShape["form_footer_title"]);
      setFormFooterParagraphs(formShape["form_footer_paragraphs"]);

      if (formShape["site_title"]) window.document.title = formShape["site_title"];

      GeneralStyling(
        formShape["styles"].body_bg_color,
        formShape["styles"].paragraphs_color,
        formShape["styles"].separators_color,
        formShape["styles"].form_bg_color,
        formShape["styles"].separators_text_color
      );
      FormStyling(formShape["styles"]);
      if (formShape["custom-css"]) {
        CustomCss(formShape["custom-css"]);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          navigate("/invalid");
        }
        return;
      }

      captureException(error);
      navigate("/error");
    }
  }

  useEffect(() => {
    GetForm();
  }, []);

  return (
    <Container>
      <FormContext.Provider value={{ handleChange }}>
        {formElements ? (
          <div className="form-container" style={{ backgroundColor: styles?.form_bg_color }}>
            {
              <div>
                {isDownloading ? (
                  <FormHeader
                    formTitle={formTitle}
                    logo={logo}
                    titleColor={styles?.form_title_color}
                    formParagraphs={formHeaderParagraphs}
                    typeOfHeader={typeOfHeader}
                  >
                    <Preloader message={preloaderMessage} />
                  </FormHeader>
                ) : (
                  <>
                    {isUploading ? (
                      <FormHeader
                        formTitle={formTitle}
                        logo={logo}
                        titleColor={styles?.form_title_color}
                        formParagraphs={formHeaderParagraphs}
                        typeOfHeader={typeOfHeader}
                      >
                        <Progress percentage={uploadPercentage} message={progressBarMessage} />
                      </FormHeader>
                    ) : (
                      <FormHeader
                        formTitle={formTitle}
                        logo={logo}
                        titleColor={styles?.form_title_color}
                        formParagraphs={formHeaderParagraphs}
                        typeOfHeader={typeOfHeader}
                      >
                        <Form>
                          {formElements
                            ? formElements.map((field, i) => <Element key={i} field={field} />)
                            : null}
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              type="submit"
                              className="btn-large btn-submit"
                              onClick={(e) => {
                                handleLoaded(e);
                              }}
                            >
                              Submit
                            </Button>
                          </div>
                        </Form>
                        {generalErrors &&
                          generalErrors.map((item) => (
                            <p className="text-error" key={item.id}>
                              {item.error_message}
                            </p>
                          ))}
                        <FormFooter footerTitle={formFooterTitle} footerParagraphs={formFooterParagraphs} />
                      </FormHeader>
                    )}
                  </>
                )}
              </div>
            }
          </div>
        ) : (
          <div className="form-container" style={{ backgroundColor: "#fff" }}>
            <h4>{errorMessage}</h4>
          </div>
        )}
      </FormContext.Provider>
    </Container>
  );
}
