import React, { useContext, useEffect, useState } from 'react'
import { FormContext } from '../Context/FormContext'
import M from 'materialize-css';
const Select = React.memo(({ field_id, field_label, field_options, field_mandatory, field_validation, field_value, field_response_errors }) => {
    const { handleChange } = useContext(FormContext)
    const [isChanged, setIsChanged] = useState(false)

    function onChangeHandle(event) {
        setIsChanged(true)
        handleChange(field_id, { target: { value: [event.target.value] } })
    }
    useEffect(() => {
        M.AutoInit();
    }, [])

    return (
        <div className="row">
            <div className={`input-field ${isChanged ? 'field-select' : ''} ${field_value ? field_value[0] ? 'field-select' : '' : ''}`} >
                <select
                    id={field_id}
                    onChange={event => onChangeHandle(event)}
                    defaultValue={field_value[0]}
                >
                    {field_options.length > 0 && field_options.map((option, i) =>
                        <option value={option.option_label} key={i}>{option.option_label}</option>
                    )}
                </select>
                <label>{field_label} {field_mandatory === "yes" ? '*' : ''}</label>
            </div>
            {
                field_validation.map((item) => {
                    if (item["show_error"] === "true")
                        return <p id={item.validation_id} key={item.validation_id} className="text-error error-element">{item["error_message"]}</p>
                    else
                        return null
                })
            }
            {
                field_response_errors ?
                    field_response_errors.map((item) => {
                        return <p key={item.id} className="text-error">{item.error_message}</p>
                    })
                    :
                    null
            }
        </div>
    )
})

export default Select