import React from 'react'

export default function Preloader({ message }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <h6>{message}</h6>
      <div style={{ marginTop: '20px' }} className="preloader-wrapper big active">
        <div className="spinner-layer spinner-teal-only">
          <div className="circle-clipper left">
            <div className="circle"></div>
          </div><div className="gap-patch">
            <div className="circle"></div>
          </div><div className="circle-clipper right">
            <div className="circle"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
