import React from 'react';

const Progress = ({ percentage, message }) => {
    const perc = percentage + "%"

    return (
        <>
            <h6>{message}</h6>
            <div className="progress">
                <div className="determinate" style={{ width: perc }}></div>
            </div>
        </>
    );
};

export default Progress;