export default function FormStyling(formStyles) {
  const {
    label_placeholder_color,
    label_active_color,
    input_text_color,
    input_border_color,
    input_valid_border_color,
    select_dropdown_font_color,
    select_dropdown_hover_background,
    select_dropdown_active_background,
    select_dropdown_active_font_color,
    checkbox_unchecked_border,
    checkbox_checked_color,
    radio_checked_color,
    text_area_color,
    button_font_color,
    button_background_color,
    button_hover_font_color,
    button_hover_background_color,
    calendar_container_font_color,
    calendar_container_background_color,
    calendar_container_buttons_font_color,
    timepicker_digital_display_background,
    timepicker_text_container_font_color,
    timepicker_text_container_selected_font_color,
    timepicker_picker_color,
    timepicker_buttons_font_color,
    calendar_header_color,
    calendar_left_display_background_color,
    calendar_left_display_font_color,
    calendar_current_day_font_color,
    calendar_selected_day_font_color,
    calendar_selected_day_background_color,
    progressbar_color,
    progressbar_loading_color,
    preloader_color,
  } = formStyles;

  var css = `

  .spinner-layer {
    border-color: ${preloader_color};
  }

  .progress {
    background-color: ${progressbar_color};
  }

  .progress .determinate {
      background-color: ${progressbar_loading_color};
  }

    .input-field > input,
    .input-field > textarea,
    .file-path-wrapper > input,
    .dx-widget,
    .select-wrapper > input {
      color: ${input_text_color} !important;        
    }



    label,
    ::placeholder,
    .dx-lookup-empty .dx-lookup-field,
    .input-field > label {
      color: ${label_placeholder_color} !important;
      }

    input:not([type]):focus:not([readonly]) + label,
    input[type=text]:not(.browser-default):focus:not([readonly]) + label,
    input[type=password]:not(.browser-default):focus:not([readonly]) + label,
    input[type=email]:not(.browser-default):focus:not([readonly]) + label,
    input[type=url]:not(.browser-default):focus:not([readonly]) + label,
    input[type=time]:not(.browser-default):focus:not([readonly]) + label,
    input[type=date]:not(.browser-default):focus:not([readonly]) + label,
    input[type=datetime]:not(.browser-default):focus:not([readonly]) + label,
    input[type=datetime-local]:not(.browser-default):focus:not([readonly]) + label,
    input[type=tel]:not(.browser-default):focus:not([readonly]) + label,
    input[type=number]:not(.browser-default):focus:not([readonly]) + label,
    input[type=search]:not(.browser-default):focus:not([readonly]) + label,
    textarea.materialize-textarea:focus:not([readonly]) + label {
      color: ${label_active_color} !important;
    }
    input:not([type]):focus:not([readonly]),
    input[type=text]:not(.browser-default):focus:not([readonly]),
    input[type=password]:not(.browser-default):focus:not([readonly]),
    input[type=email]:not(.browser-default):focus:not([readonly]),
    input[type=url]:not(.browser-default):focus:not([readonly]),
    input[type=time]:not(.browser-default):focus:not([readonly]),
    input[type=date]:not(.browser-default):focus:not([readonly]),
    input[type=datetime]:not(.browser-default):focus:not([readonly]),
    input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
    input[type=tel]:not(.browser-default):focus:not([readonly]),
    input[type=number]:not(.browser-default):focus:not([readonly]),
    input[type=search]:not(.browser-default):focus:not([readonly]),
    textarea.materialize-textarea:focus:not([readonly]) {
      border-bottom: 1px solid ${label_active_color} !important;
      -webkit-box-shadow: 0 1px 0 0 ${label_active_color} !important;
      box-shadow: 0 1px 0 0 ${label_active_color} !important;
    }

    .select-wrapper input.select-dropdown:focus {
      border-bottom: 1px solid ${label_active_color} !important;
    }


    .select-dropdown.dropdown-content li > span {
      color: ${select_dropdown_font_color} !important;
    }

    .select-dropdown.dropdown-content li:hover {
      background-color: ${select_dropdown_hover_background} !important;
    }

    .select-dropdown.dropdown-content li.selected {
      background-color: ${select_dropdown_active_background} !important;
    }

    .select-dropdown.dropdown-content li.selected > span {
      color: ${select_dropdown_active_font_color} !important;
    }

    [type="checkbox"].filled-in:not(:checked) + span:not(.lever)::after {        
      border: 2px solid ${checkbox_unchecked_border};
    }

    [type="checkbox"].filled-in:checked + span:not(.lever)::after {
      border: 2px solid ${checkbox_checked_color};
      background-color: ${checkbox_checked_color};
    }

    [type="radio"]:checked+span:after {
      background-color: ${radio_checked_color};
      border: 2px solid ${radio_checked_color};
    }

    .btn {
      background-color: ${button_background_color};
      color: ${button_font_color};
    }

    .btn:hover {
      background-color: ${button_hover_background_color};
      color: ${button_hover_font_color};
    }

    button:focus {
      background-color: ${button_hover_background_color};
      color: ${button_hover_font_color};
    }

    textarea.materialize-textarea:focus:not([readonly]) + label{
      color: ${text_area_color} !important;
    }

    textarea.materialize-textarea:focus:not([readonly]){
      border-bottom: 1px solid ${text_area_color} !important;
    }

    input[type="text"]:not(.browser-default),
    .dx-lookup-empty .dx-lookup-field {
      border-bottom: 1px solid ${input_border_color} !important;
      box-shadow: none;
    }

    .input-field > textarea {
        border-bottom: 1px solid ${input_border_color};
      box-shadow: none;
    }

    input.valid[type="text"]:not(.browser-default),
    .dx-lookup-field,
    .field-select .select-wrapper input { 
      border-bottom: 1px solid ${input_valid_border_color} !important;
      box-shadow: 0 1px 0 0 ${input_valid_border_color};
    }

    .datepicker-calendar-container {
      color: ${calendar_container_font_color};
      background-color: ${calendar_container_background_color};
    }

    .datepicker-cancel, .datepicker-clear, .datepicker-today, .datepicker-done {
      color: ${calendar_container_buttons_font_color};
    }

    .datepicker-table abbr {
      color: ${calendar_header_color};
    }

    .datepicker-controls .select-wrapper input {
        color: ${calendar_header_color} !important;
        border-bottom: 1px solid ${calendar_header_color} !important;
    }

    .month-prev svg, .month-next svg {
      fill: ${calendar_header_color};
    }

    .datepicker-date-display {
      background-color: ${calendar_left_display_background_color};
      color: ${calendar_left_display_font_color};
    }

    .datepicker-date-display .year-text {
      color: ${calendar_left_display_font_color}
    }

    .datepicker-table td.is-selected {
      background-color: ${calendar_selected_day_background_color};
      color: ${calendar_selected_day_font_color};
    }

    .datepicker-table td.is-today {
      color: ${calendar_current_day_font_color};
    }

    .timepicker-digital-display {
      background-color: ${timepicker_digital_display_background};        
    }      

    .timepicker-text-container {
      color: ${timepicker_text_container_font_color};
    }

    .timepicker-span-am-pm .text-primary {
      color: ${timepicker_text_container_selected_font_color} !important;
    }


    .timepicker-canvas line {
      stroke: ${timepicker_picker_color};
    } 

    .timepicker-canvas-bg,
    .timepicker-canvas-bearing {
      fill: ${timepicker_picker_color};
    }

    .timepicker-close, .timepicker-clear {
      color: ${timepicker_buttons_font_color};
    }

    `;
  var style = document.createElement("style");

  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }

  document.getElementsByTagName("head")[0].appendChild(style);
}
