import React, { useContext } from "react";
import { FormContext } from "../Context/FormContext";
export default React.memo(function TimeInput({
  field_id,
  field_label,
  field_mandatory,
  field_validation,
  field_response_errors
}) {
  const { handleChange } = useContext(FormContext);

  function valueChanged(event) {
    handleChange(field_id, { target: { value: [event.target.value] } });
  }

  return (
    <div className="row">
      <div className="input-field">
        <input
          id={field_id}
          type="text"
          className="timepicker validate"
          onSelect={(event) => valueChanged(event)}
        />
        <label htmlFor={field_id}>
          {field_label} {field_mandatory === "yes" ? "*" : ""}
        </label>
      </div>
      {field_validation.map((item) => {
        if (item["show_error"] === "true")
          return <p id={item.validation_id} key={item.validation_id} className="text-error error-element">{item["error_message"]}</p>
        else return null;
      })}
      {
        field_response_errors ?
          field_response_errors.map((item) => {
            return <p key={item.id} className="text-error">{item.error_message}</p>
          })
          :
          null
      }
    </div>
  );
})

