/*
*  Function for updating inputs value and validation when user interacts with them
*/
export function generalChange(field, event) {
    const val = event.target.value
    if (field.field_mandatory === "yes") {
        if (val.length > 0 && val[0].trim().length > 0) {
            field.field_validation[0]["show_error"] = "false"
        } else if (event.target.isOtherSelected === true) {
            field.field_validation[0]["show_error"] = "false"
        } else {
            field.field_validation[0]["show_error"] = "true"
        }
    }

    if (event.target.additionalChange) {
        field.field_other["field_value"] = event.target.value
        if (event.target.value.trim().length > 0)
            field.field_validation[0]["show_error"] = "false"
        else
            field.field_validation[0]["show_error"] = "true"
    } else {
        field["field_value"] = event.target.value;
    }
    return field
}

export function checkboxChange(field, event) {
    if (field.field_mandatory === "yes") {
        if (event.target.value[0] === "true") {
            field.field_validation[0]["show_error"] = "false"
        } else {
            field.field_validation[0]["show_error"] = "true"
        }
    }
    field["field_value"] = event.target.value;

    return field
}

export function fileChange(field, event) {
    if (field.field_mandatory === "yes") {
        field.field_validation[0]["show_error"] = "false"
    }
    field["field_value"] = event.target.value

    return field
}

/*
* Create data object to send with post request
*/

export function createDataForPostRequest(formElements) {
    const requestData = []

    formElements.forEach((field) => {
        if (field.field_type !== "formParagraph" && field.field_type !== "separator") {
            if (field.field_type === "file") {
                requestData.push({
                    field_id: field.field_id,
                    field_label: field.field_label,
                    files: field.field_value,
                });
            } else if (field.field_type === "multipleFile") {
                requestData.push({
                    field_id: field.field_id,
                    field_label: field.field_label,
                    files: field.field_value,
                });
            } else {
                if (field.field_other) {
                    if (field.field_other["field_value"]) {
                        field.field_value.push(field.field_other["field_value"])
                    }
                }

                requestData.push({
                    field_id: field.field_id,
                    field_label: field.field_label,
                    field_value: field.field_value,
                });
            }
        }
    });

    return requestData
}

