import React from "react";
import robotGif from "./img/robot.gif";

export default function ErrorPage() {
    return (
        <div className="err-container">
            <div className="err-content">
                <div className="err-header">
                    <img src={robotGif} width="128" height="128" />
                    <div className="err-header-text">
                        <span className="err-header-text-title">Oooops!</span>
                        <span className="err-header-text-desc">Houston, we have a problem!</span>
                    </div>
                </div>
                <p style={{ color: "#000", fontSize: "1.2rem", marginTop: "3.5rem" }}>
                    There was an problem with server, admins have been notified.
                </p>
                <p style={{ color: "#000", fontSize: "1.2rem" }}>Please try again later.</p>
            </div>
        </div>
    );
}
