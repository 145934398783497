import React, { useContext } from "react";
import { FormContext } from "../Context/FormContext";

export default React.memo(function Radio({
  field_options,
  field_id,
  field_mandatory,
  field_label,
  field_validation,
  field_other,
  field_value,
  field_response_errors,
}) {
  const { handleChange } = useContext(FormContext);

  function valueChange(event) {
    handleChange(field_id, { target: { value: [event.target.id] } });
  }

  function valueWithOtherChange(event) {
    let result = [];
    let isOther = false;

    console.log(field_other);

    if (event.target.id === field_other.show_on_value) {
      field_other.is_visible = true;
      isOther = true;
    } else {
      result.push(event.target.id);
      field_other["field_value"] = "";
      field_other.is_visible = false;
    }

    handleChange(field_id, { target: { value: result, isOtherSelected: isOther } });
  }

  function valueOtherChange(event) {
    handleChange(field_id, { target: { value: event.target.value, additionalChange: true } });
  }

  return (
    <div className="row">
      <label style={{ marginBottom: "15px" }}>
        {field_label} {field_mandatory === "yes" ? "*" : ""}
      </label>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {field_options
          ? field_options.map((item, i) => {
              return (
                <p key={i} style={{ marginRight: "35px" }}>
                  <label>
                    <input
                      onChange={field_other ? valueWithOtherChange : valueChange}
                      name={`${field_id}`}
                      type="radio"
                      id={item.option_label}
                      checked={
                        field_value?.includes(item.option_label)
                          ? true
                          : field_other?.is_visible === true &&
                            item.option_label === field_other?.show_on_value
                          ? true
                          : false
                      }
                    />
                    <span>{item.option_label}</span>
                  </label>
                </p>
              );
            })
          : null}
      </div>
      {field_other?.is_visible === true ? (
        <div style={{ width: "50%" }}>
          <input
            type="text"
            onChange={valueOtherChange}
            className={field_other?.field_value ? "validate valid" : "validate"}
            value={field_other?.field_value ? field_other?.field_value : ""}
            placeholder={field_other?.field_placeholder}
          />
        </div>
      ) : null}
      {field_validation.map((item) => {
        if (item["show_error"] === "true")
          return (
            <p id={item.validation_id} key={item.validation_id} className="text-error error-element">
              {item["error_message"]}
            </p>
          );
        else return null;
      })}
      {field_response_errors
        ? field_response_errors.map((item) => {
            return (
              <p key={item.id} className="text-error">
                {item.error_message}
              </p>
            );
          })
        : null}
    </div>
  );
});
