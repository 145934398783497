import React from "react";
import { Image } from "react-bootstrap";

export default function FormHeader({ formTitle, children, logo, titleColor, formParagraphs, typeOfHeader }) {
  switch (typeOfHeader) {
    case "row":
      return (
        <div>
          <div style={{ marginBottom: "40px" }}>
            <div className="form-header">
              <div dangerouslySetInnerHTML={{ __html: formTitle }} />
              <Image className="form-logo" src={logo} alt="Optistartech" />
            </div>
            <div style={{ marginTop: "30px" }}>
              {formParagraphs
                ? formParagraphs.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="custom-paragraphs"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    );
                  })
                : null}
            </div>
          </div>
          {children}
        </div>
      );
      break;
    case "column":
      return (
        <div>
          <div style={{ marginBottom: "2.5rem" }}>
            <div className="form-header">
              <Image className="column-logo" src={logo} alt="Think1st" />
            </div>
            <div dangerouslySetInnerHTML={{ __html: formTitle }} />
            <div style={{ marginTop: "1rem" }}>
              {formParagraphs
                ? formParagraphs.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="custom-paragraphs"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    );
                  })
                : null}
            </div>
          </div>
          {children}
        </div>
      );
      break;
    default:
      return <div>Invalid header type</div>;
  }
}
