import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import FormComponent from "./FormComponent";
import SuccessMessage from "./SuccessMessage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Invalid from "./Invalid/Invalid";
import ErrorPage from "./ErrorPage";

export default function App() {
    return (
        <Router>
            <Routes>
                <Route path="/thankyou" element={<SuccessMessage />} />
                <Route path="/:form/:hash" element={<FormComponent />} />
                <Route path="/error" element={<ErrorPage />} />
                <Route path="*" element={<Invalid />} />
            </Routes>
        </Router>
    );
}
