import React, { useContext, useEffect } from "react";
import { FormContext } from "../Context/FormContext";
import M from "materialize-css";

export default React.memo(function DateInput({
    field_id,
    field_label,
    field_mandatory,
    field_validation,
    field_value,
    field_response_errors,
}) {
    const { handleChange } = useContext(FormContext);

    var elems = document.querySelectorAll(".datepicker");
    M.Datepicker.init(elems, {
        format: "dd/mm/yyyy",
        showClearBtn: "true",
        defaultDate: new Date(),
        yearRange: [1950, new Date().getFullYear()],
    });

    useEffect(() => {
        function valueChanged(event) {
            handleChange(field_id, { target: { value: [event.target.value] } });
        }

        document.querySelector(`#${field_id}`).addEventListener("change", (e) => {
            valueChanged(e);
        });
    }, [field_id]);

    return (
        <div className="row">
            <div className="input-field">
                <input
                    onFocus={() => {
                        const elem = document.querySelector(`#${field_id}`);
                        const instance = M.Datepicker.getInstance(elem);
                        instance.open();
                    }}
                    id={field_id}
                    value={field_value ? (field_value[0] ? field_value[0] : "") : ""}
                    onChange={() => {
                        console.log("");
                    }}
                    type="text"
                    className={`datepicker ${field_value ? (field_value[0] ? "validate valid" : "validate") : "validate"} `}
                />
                <label className={field_value ? (field_value[0] ? "active" : "") : ""} htmlFor={field_id}>
                    {field_label} {field_mandatory === "yes" ? "*" : ""}
                </label>
            </div>
            {field_validation.map((item) => {
                if (item["show_error"] === "true")
                    return (
                        <p key={item.validation_id} className="text-error">
                            {item["error_message"]}
                        </p>
                    );
                else return null;
            })}
            {field_response_errors
                ? field_response_errors.map((item) => {
                      return (
                          <p key={item.id} className="text-error">
                              {item.error_message}
                          </p>
                      );
                  })
                : null}
        </div>
    );
});
