import React from 'react'

export default React.memo(function Paragraph({ field_value }) {

    function createMarkup() {
        return { __html: `${field_value}` }
    }

    return (
        <div dangerouslySetInnerHTML={createMarkup()} />
    )
})
