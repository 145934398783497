import React from 'react'

export default function FormFooter({ footerTitle, footerParagraphs }) {
    return (
        <div style={{ marginTop: '60px' }}>
            <div dangerouslySetInnerHTML={{ __html: footerTitle }} />
            <div style={{ marginTop: '30px' }}>
                {
                    footerParagraphs ?
                        footerParagraphs.map((item) => {
                            return (
                                <div key={item.id} className="custom-paragraphs" dangerouslySetInnerHTML={{ __html: item.text }} />
                            )
                        })
                        :
                        null
                }
            </div>
        </div>
    )
}
