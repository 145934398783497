import React, { useContext, useState } from "react";
import { FormContext } from "../Context/FormContext";

export default React.memo(function GroupCheckbox({
    field_options,
    field_id,
    field_mandatory,
    field_label,
    field_validation,
    field_other,
    field_value,
    field_response_errors,
}) {
    const { handleChange } = useContext(FormContext);
    const [selectedValues, setSelectedValues] = useState(field_value ? field_value : []);
    const [isOtherSelected, setIsOtherSelected] = useState(false);

    function valueChange(event) {
        let newArr = [];
        if (selectedValues.includes(event.target.id)) {
            newArr = selectedValues.filter((item) => item !== event.target.id);
        } else {
            newArr = [...selectedValues];
            newArr.push(event.target.id);
        }

        setSelectedValues(newArr);
        handleChange(field_id, { target: { value: newArr } });
    }

    function valueWithOtherChange(event) {
        let newArr = [];
        let isOther = isOtherSelected;

        if (selectedValues.includes(event.target.id)) {
            newArr = selectedValues.filter((item) => item !== event.target.id);
        } else {
            if (field_other?.show_on_value === event.target.id && isOtherSelected === false) {
                newArr = [...selectedValues];
                if (field_other) {
                    field_other.is_visible = true;
                }
                isOther = true;
            } else if (field_other?.show_on_value === event.target.id && isOtherSelected === true) {
                newArr = [...selectedValues];
                if (field_other) {
                    field_other.is_visible = false;
                    field_other.field_value = "";
                }
                isOther = false;
            } else if (field_other?.show_on_value !== event.target.id) {
                newArr = [...selectedValues, event.target.id];
            }
        }

        setIsOtherSelected(isOther);
        setSelectedValues(newArr);
        handleChange(field_id, { target: { value: newArr, isOtherSelected: isOther } });
    }

    function valueOtherChange(event) {
        handleChange(field_id, { target: { value: event.target.value, additionalChange: true } });
    }

    return (
        <div style={{ marginBottom: "3rem" }}>
            <label style={{ marginBottom: "15px" }}>
                {field_label} {field_mandatory === "yes" ? "*" : ""}
            </label>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {field_options
                    ? field_options.map((item, i) => {
                          return (
                              <p key={i} style={{ marginRight: "35px" }}>
                                  <label>
                                      <input
                                          onChange={field_other ? valueWithOtherChange : valueChange}
                                          name={`${field_id}`}
                                          type="checkbox"
                                          id={item.option_label}
                                          checked={
                                              field_value?.includes(item.option_label)
                                                  ? true
                                                  : field_other?.is_visible === true &&
                                                    item.option_label === field_other?.show_on_value
                                                  ? true
                                                  : false
                                          }
                                      />
                                      <span>{item.option_label}</span>
                                  </label>
                              </p>
                          );
                      })
                    : null}
            </div>
            {field_other?.is_visible === true ? (
                <div style={{ width: "50%" }}>
                    <input
                        type="text"
                        value={field_other?.field_value ? field_other?.field_value : ""}
                        className={field_other?.field_value ? "validate valid" : "validate"}
                        onChange={valueOtherChange}
                        placeholder={field_other?.field_placeholder}
                    />
                </div>
            ) : null}
            {field_validation.map((item) => {
                if (item["show_error"] === "true")
                    return (
                        <p id={item.validation_id} key={item.validation_id} className="text-error error-element">
                            {item["error_message"]}
                        </p>
                    );
                else return null;
            })}
            {field_response_errors
                ? field_response_errors.map((item) => {
                      return (
                          <p key={item.id} className="text-error">
                              {item.error_message}
                          </p>
                      );
                  })
                : null}
        </div>
    );
});
