import React from "react";
import Checkbox from "./FormComponents/Checkbox";
import Input from "./FormComponents/Input";
import LookupField from "./FormComponents/Lookup";
import Select from "./FormComponents/Select";
import FilesInput from "./FormComponents/FilesInput";
import MultifileInput from "./FormComponents/MultifileInput";
import MemoInput from "./FormComponents/MemoInput";
import DateInput from "./FormComponents/DateInput";
import TimeInput from "./FormComponents/TimeInput";
import Paragraph from "./FormComponents/Paragraph";
import Separator from "./FormComponents/Separator";
import Radio from "./FormComponents/Radio";
import GroupCheckbox from "./FormComponents/GroupCheckbox";

const Element = ({
    field: {
        field_type,
        field_id,
        field_label,
        field_placeholder,
        field_value,
        field_options,
        field_mandatory,
        field_dspExpr,
        field_items,
        field_file_type,
        field_max_size_bytes,
        field_validation,
        field_single_size_bytes,
        field_response_errors,
        field_other,
    },
}) => {
    switch (field_type) {
        case "text":
            return (
                <Input
                    field_id={field_id}
                    field_label={field_label}
                    field_value={field_value}
                    field_mandatory={field_mandatory}
                    field_validation={field_validation}
                    field_response_errors={field_response_errors}
                    field_placeholder={field_placeholder}
                />
            );
        case "select":
            return (
                <Select
                    field_id={field_id}
                    field_mandatory={field_mandatory}
                    field_label={field_label}
                    field_placeholder={field_placeholder}
                    field_value={field_value}
                    field_options={field_options}
                    field_validation={field_validation}
                    field_response_errors={field_response_errors}
                />
            );
        case "checkbox":
            return (
                <Checkbox
                    field_id={field_id}
                    field_label={field_label}
                    field_value={field_value}
                    field_mandatory={field_mandatory}
                    field_validation={field_validation}
                    field_response_errors={field_response_errors}
                />
            );

        case "lookup":
            return (
                <LookupField
                    field_id={field_id}
                    field_label={field_label}
                    field_value={field_value}
                    field_placeholder={field_placeholder}
                    field_mandatory={field_mandatory}
                    field_dspExpr={field_dspExpr}
                    field_items={field_items}
                    field_validation={field_validation}
                    field_response_errors={field_response_errors}
                />
            );
        case "file":
            return (
                <FilesInput
                    field_id={field_id}
                    field_label={field_label}
                    field_value={field_value}
                    field_max_size_bytes={field_max_size_bytes}
                    field_mandatory={field_mandatory}
                    field_file_type={field_file_type}
                    field_validation={field_validation}
                    field_response_errors={field_response_errors}
                />
            );

        case "multipleFile":
            return (
                <MultifileInput
                    field_id={field_id}
                    field_label={field_label}
                    field_mandatory={field_mandatory}
                    field_file_type={field_file_type}
                    field_max_size_bytes={field_max_size_bytes}
                    field_single_size_bytes={field_single_size_bytes}
                    field_validation={field_validation}
                    field_response_errors={field_response_errors}
                />
            );
        case "textarea":
            return (
                <MemoInput
                    field_id={field_id}
                    field_label={field_label}
                    field_mandatory={field_mandatory}
                    field_value={field_value}
                    field_validation={field_validation}
                    field_response_errors={field_response_errors}
                />
            );
        case "date":
            return (
                <DateInput
                    field_id={field_id}
                    field_label={field_label}
                    field_mandatory={field_mandatory}
                    field_value={field_value}
                    field_validation={field_validation}
                    field_response_errors={field_response_errors}
                />
            );
        case "time":
            return (
                <TimeInput
                    field_id={field_id}
                    field_label={field_label}
                    field_mandatory={field_mandatory}
                    field_value={field_value}
                    field_validation={field_validation}
                    field_response_errors={field_response_errors}
                />
            );
        case "formParagraph":
            return <Paragraph field_value={field_value} />;
        case "separator":
            return <Separator field_value={field_value} />;
        case "radio":
            return (
                <Radio
                    field_id={field_id}
                    field_options={field_options}
                    field_mandatory={field_mandatory}
                    field_label={field_label}
                    field_validation={field_validation}
                    field_other={field_other}
                    field_value={field_value}
                    field_response_errors={field_response_errors}
                />
            );
        case "groupcheckbox":
            return (
                <GroupCheckbox
                    field_id={field_id}
                    field_options={field_options}
                    field_mandatory={field_mandatory}
                    field_label={field_label}
                    field_validation={field_validation}
                    field_other={field_other}
                    field_value={field_value}
                    field_response_errors={field_response_errors}
                />
            );

        default:
            return null;
    }
};

export default Element;
